<template>
    <div>
        <v-subheader>{{ dateComputed }}</v-subheader>

        <v-container class="py-0" fluid>
            <h3 v-if="number">
                Заказ №{{ number }}
                <v-chip
                    v-if="status"
                    class="ma-2"
                    :color="status.color"
                    label
                    text-color="white"
                >
                    {{ status.name }}
                </v-chip>
            </h3>

            <v-simple-table v-if="products && products.length">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left text-no-wrap">Наименование</th>
                            <th class="text-center text-no-wrap">Кол-во</th>
                            <th class="text-center text-no-wrap">Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in products" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td class="text-center text-no-wrap">{{ item.quantity }}</td>
                            <td class="text-center text-no-wrap">{{ (item.price * item.quantity) | currency }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>
        <v-container fluid>
            <template v-if="orderSum !== undefined">
                <v-row align="center" justify="space-between">
                    <v-col cols="auto">
                        ИТОГО:
                    </v-col>
                    <v-col cols="auto">
                        <div class="headline">{{ orderSum | currency }}</div>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
    import {
        VContainer,
        VRow,
        VCol,

        VSubheader,

        VSimpleTable,

        VChip,
    } from 'vuetify/lib'

    import {
        forEach as _forEach,
    } from 'lodash'

    import moment from 'moment'

    export default {
        name: 'OrderView',

        components: {
            VContainer,
            VRow,
            VCol,

            VSubheader,

            VSimpleTable,

            VChip,
        },

        props: {
            date: { type: [String, Object], default: null },
            number: { type: String, default: null },
            products: { type: Array, default: null },
            status: { type: Object, default: null },
        },

        data() {
            return {}
        },

        computed: {
            dateComputed() {
                return moment(this.date).locale('ru').format('D MMMM Y')
            },

            orderSum() {
                let sum = 0

                _forEach(this.products, Product => {
                    sum += Product.price * Product.quantity
                })

                return sum
            },
        }
    }
</script>
